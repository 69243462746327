.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.setup-flow {
  min-height: 600px;
}


.overviewFlow {
  min-height: 400px;
  /* background-color: purple; */
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 50px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 10px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  font-weight: bold;
}

.dndflow .dndnode.user {
  background: #456bac;
  color: #ffffff;
  border-color: #24375c;
}

.dndflow .dndnode.source {
  background: #e8effa;
  color: #24375c;
  border-color: #24375c;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

.text-updater-node {
  height: 50px;
  border: 1px solid #eee;
  padding: 5px;
  border-radius: 5px;
  background: white;
}

.text-updater-node label {
  display: block;
  color: #777;
  font-size: 12px;
}

/*
  #e8effa water source
  #456bac water user
  #a2b9e6 flow
  #fd942b changes
  #24375c border
  */

.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.buottons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-node {
  min-width: 100px;
  max-width: 150px;
  min-height: 42px;
  text-align: center;
}

.custom-node-button {
  width: 15px;
  height: 15px;
  font-size: 10px;
  border-radius: 100%;
  border: 1px solid;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.overviewFlow .react-flow__handle {
  background-color: white;
  border: none;
  min-height: 0;
  min-width: 0;
  height: 1px;
  width: 1px;
}

.overviewFlow .react-flow__edge {
  cursor: auto;
}

.overviewFlow .react-flow__handle {
  background-color: white;
  border: none;
  min-height: 0;
  min-width: 0;
  height: 1px;
  width: 1px;
}
